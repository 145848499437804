/*
 * Copyright 2008 Kiyotaka Kouno
 */

export default class ResultRedirect {

    private ResultRedirect() { }

    public static redirect(result) {
        const str = result.text;

        if (str.match(/^https?:\/\//gi) != null) {
            if (window.open(str, '_blank')) {
            }
            else {
                window.location.href = str;
            }
        }
        else if (str.match(/^2[0-9]{12}/) != null) {
            const url = 'https://xpand.codes/viewer/callback.php?code=' + str + '&amp;app=xpand';
            if (window.open(url, '_blank')) {
            }
            else {
                window.location.href = url;
            }
        }
        else if (str.match(/^[0-9]{13}/) != null) {
            // this.getAmazonPage(str); // 2018.09.14 変更
            const url = 'https://xpand.codes/viewer/ean.php?ean=' + str + '&app=xpandjs';
            if (window.open(url, '_blank')) {
            }
            else {
                window.location.href = url;
            }
        }
        else {
            const url = 'https://xpand.codes/viewer/textviewer.php?text=' + str + '&amp;app=xpand';
            if (window.open(url, '_blank')) {
            }
            else {
                window.location.href = url;
            }
        }
    }

/*
    private static getAmazonPage(ean) {
        const lang = this.getLanguage(ean);
        if (lang === 'ja') {
            const url = 'https://www.amazon.co.jp/s/?field-keywords=' + ean + '&amp;tag=programmagiche-22';
            if (window.open(url, '_blank')) {
            }
            else {
                window.location.href = url;
            }
        }
        else {
            const url = 'https://www.google.com/search?q=ean+' + ean;
            if (window.open(url, '_blank')) {
            }
            else {
                window.location.href = url;
            }
        }
    }

    private static getLanguage(ean) {
        try {
            return (navigator.language).substr(0, 2);
        }
        catch (e) {
            return undefined;
        }
    }
*/
}
