/*
 * Copyright 2009 ZXing authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*namespace com.google.zxing.common {*/

import Binarizer from './../Binarizer';
import LuminanceSource from './../LuminanceSource';
import BitArray from './BitArray';
import BitMatrix from './BitMatrix';

import NotFoundException from '../NotFoundException';

/**
 * このBinarizerの実装では、古いZXingグローバルヒストグラム手法を使用します。
 * ローカル閾値処理アルゴリズムを使用するのに十分なCPUまたはメモリを持たないローエンドのモバイルデバイスに適しています。 しかし、グローバルな黒点を選ぶので、難しい影や勾配を扱うことはできません。
 * より速いモバイルデバイスとすべてのデスクトップアプリケーションはおそらくHybridBinarizerを使用するべきです。

 * @author dswitkin@google.com (Daniel Switkin)
 * @author Sean Owen
 */
export default class GlobalHistogramBinarizer extends Binarizer {

    private static LUMINANCE_BITS = 5;
    private static LUMINANCE_SHIFT = 8 - GlobalHistogramBinarizer.LUMINANCE_BITS;
    private static LUMINANCE_BUCKETS = 1 << GlobalHistogramBinarizer.LUMINANCE_BITS;
    private static EMPTY = Uint8ClampedArray.from([0]);

    private luminances: Uint8ClampedArray;
    private buckets: Int32Array;

    public constructor(source: LuminanceSource) {
        super(source);
        this.luminances = GlobalHistogramBinarizer.EMPTY;
        this.buckets = new Int32Array(GlobalHistogramBinarizer.LUMINANCE_BUCKETS);
    }

    // 1Dリーダーのパフォーマンスを向上させるために行データに単純なシャープネスを適用します。
    /*@Override*/
    public getBlackRow(y: number /*int*/, row: BitArray): BitArray /*throws NotFoundException*/ {
        const source = this.getLuminanceSource();
        const width = source.getWidth();
        if (row === undefined || row === null || row.getSize() < width) {
            row = new BitArray(width);
        } else {
            row.clear();
        }

        this.initArrays(width);
        const localLuminances = source.getRow(y, this.luminances);
        const localBuckets = this.buckets;
        for (let x = 0; x < width; x++) {
            localBuckets[(localLuminances[x] & 0xff) >> GlobalHistogramBinarizer.LUMINANCE_SHIFT]++;
        }
        const blackPoint = GlobalHistogramBinarizer.estimateBlackPoint(localBuckets);
/*
        if (width < 3) {
            // Special case for very small images
            for (let x = 0; x < width; x++) {
                if ((localLuminances[x] & 0xff) < blackPoint) {
                    row.set(x);
                }
            }
        } else {
            let left = localLuminances[0] & 0xff;
            let center = localLuminances[1] & 0xff;
            for (let x = 1; x < width - 1; x++) {
                const right = localLuminances[x + 1] & 0xff;
                // A simple -1 4 -1 box filter with a weight of 2.
                if (((center * 4) - left - right) / 2 < blackPoint) {
                    row.set(x);
                }
                left = center;
                center = right;
            }
        }
*/
        let left = localLuminances[0] & 0xff;
        let center = localLuminances[1] & 0xff;
        for (let x = 1; x < width - 1; x++) {
            const right = localLuminances[x + 1] & 0xff;
            // A simple -1 4 -1 box filter with a weight of 2.
            if (((center * 4) - left - right) / 2 < blackPoint) {
                row.set(x);
            }
            left = center;
            center = right;
        }

        return row;
    }

    // この呼び出しは2Dリーダーのみで使用されるため、データをシャープにしません。
    /*@Override*/
    public getBlackMatrix(): BitMatrix /*throws NotFoundException*/ {
        const source = this.getLuminanceSource();
        const width = source.getWidth();
        const height = source.getHeight();
        const matrix = new BitMatrix(width, height);

        // イメージから4つの行をサンプリングすることによって、ヒストグラムを素早く計算します。
        // これは、以前のように対角線をサンプリングするよりも、ブラックボックステストでより堅牢であることが証明されました。
        this.initArrays(width);
        const localBuckets = this.buckets;
        for (let y = 1; y < 5; y++) {
            const row = height * y / 5;
            const localLuminances = source.getRow(row, this.luminances);
            const right = Math.floor((width * 4) / 5);
            for (let x = Math.floor(width / 5); x < right; x++) {
                const pixel = localLuminances[x] & 0xff;
                localBuckets[pixel >> GlobalHistogramBinarizer.LUMINANCE_SHIFT]++;
            }
        }
        const blackPoint = GlobalHistogramBinarizer.estimateBlackPoint(localBuckets);

        // 我々は、黒点推定が成功するまで画像の輝度全体を読み込むのを遅らせる。
        // 私たちは4行を2度読むことになりますが、それは私たちのモットーと一貫しています。連続スキャンに必要な「すぐに失敗する」
        const localLuminances = source.getMatrix();
        for (let y = 0; y < height; y++) {
            const offset = y * width;
            for (let x = 0; x < width; x++) {
                const pixel = localLuminances[offset + x] & 0xff;
                if (pixel < blackPoint) {
                    matrix.set(x, y);
                }
            }
        }

        return matrix;
    }

    /*@Override*/
    public createBinarizer(source: LuminanceSource): Binarizer {
        return new GlobalHistogramBinarizer(source);
    }

    private initArrays(luminanceSize: number /*int*/): void {
        if (this.luminances.length < luminanceSize) {
            this.luminances = new Uint8ClampedArray(luminanceSize);
        }
        const buckets = this.buckets;
        for (let x = 0; x < GlobalHistogramBinarizer.LUMINANCE_BUCKETS; x++) {
            buckets[x] = 0;
        }
    }

    private static estimateBlackPoint(buckets: Int32Array): number /*int*/ /*throws NotFoundException*/ {
        // ヒストグラムの中で最も高いピークを見つける。
        const numBuckets = buckets.length;
        let maxBucketCount = 0;
        let firstPeak = 0;
        let firstPeakSize = 0;
        for (let x = 0; x < numBuckets; x++) {
            if (buckets[x] > firstPeakSize) {
                firstPeak = x;
                firstPeakSize = buckets[x];
            }
            if (buckets[x] > maxBucketCount) {
                maxBucketCount = buckets[x];
            }
        }

        // 最も高いピークから幾分離れた2番目に高いピークを見つける。
        let secondPeak = 0;
        let secondPeakScore = 0;

        for (let x = 0; x < numBuckets; x++) {
            const distanceToBiggest = x - firstPeak;
            // Encourage more distant second peaks by multiplying by square of distance.
            const score = buckets[x] * distanceToBiggest * distanceToBiggest;
            if (score > secondPeakScore) {
                secondPeak = x;
                secondPeakScore = score;
            }
        }

        // firstPeakが黒いピークに対応していることを確認してください。
        if (firstPeak > secondPeak) {
            const temp = firstPeak;
            firstPeak = secondPeak;
            secondPeak = temp;
        }

        // If there is too little contrast in the image to pick a meaningful black point, throw rather
        // than waste time trying to decode the image, and risk false positives.
        if (secondPeak - firstPeak <= numBuckets / 16) {
            throw new NotFoundException();
        }

        // Find a valley between them that is low and closer to the white peak.
        let bestValley = secondPeak - 1;
        let bestValleyScore = -1;
        for (let x = secondPeak - 1; x > firstPeak; x--) {
            const fromFirst = x - firstPeak;
            const score = fromFirst * fromFirst * (secondPeak - x) * (maxBucketCount - buckets[x]);
            if (score > bestValleyScore) {
                bestValley = x;
                bestValleyScore = score;
            }
        }

        return bestValley << GlobalHistogramBinarizer.LUMINANCE_SHIFT;
    }

}
