(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("text-encoding"));
	else if(typeof define === 'function' && define.amd)
		define("xpandreader", [""], factory);
	else if(typeof exports === 'object')
		exports["xpandreader"] = factory(require("text-encoding"));
	else
		root["xpandreader"] = factory(root[""]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__90__) {
return 